import { Alignment, Classes } from "@blueprintjs/core";
import { useReactFlow } from "@xyflow/react";
import { rgba } from "polished";
import React, { useCallback } from "react";
import styled from "styled-components";

import {
  HexButton,
  HexMenu,
  HexMenuItem,
  HexPopover,
  HexRichSwitch,
  HexSwitch,
  HexTooltip,
} from "../../hex-components";
import { LocalStorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import { useProjectContext } from "../../util/projectContext";
import {
  ArrowDownIcon,
  ArrowRightIcon,
  EyeOpenIcon,
  SingleChevronDownIcon,
  StopwatchIcon,
  ZoomInIcon,
  ZoomOutIcon,
  ZoomToFitIcon,
} from "../icons/CustomIcons";

export enum FlowOrientation {
  Vertical = "Vertical",
  Horizontal = "Horizontal",
}

const Wrapper = styled.div`
  position: absolute;
  top: 44px;
  right: 10px;
  z-index: 10;
  display: flex;
  gap: 2px;

  background-color: ${({ theme }) => rgba(theme.backgroundColor.LOGIC, 0.95)};
`;

const DisplaySettingsButton = styled(HexButton)`
  padding: 0 6px;

  .${Classes.ICON}:first-child {
    margin-right: 2px;
  }
`;

export const GraphActions: React.ComponentType = React.memo(
  function GraphActions() {
    const { hexId } = useProjectContext();

    const { fitView, zoomIn, zoomOut } = useReactFlow();

    const [showLastRunTimes, setshowLastRunTimes] = useLocalStorage(
      LocalStorageKeys.GRAPH_OPTION.SHOW_LAST_RUN_TIMES({ hexId }),
    );

    const [flowOrientation, setFlowOrientation] = useLocalStorage(
      LocalStorageKeys.GRAPH_OPTION.FLOW_ORIENTATION({ hexId }),
    );

    const [hideUnlinkedCells, sethideUnlinkedCells] = useLocalStorage(
      LocalStorageKeys.GRAPH_OPTION.HIDE_UNLINKED_CELLS({ hexId }),
    );

    const [combineEdges, setCombineEdges] = useLocalStorage(
      LocalStorageKeys.GRAPH_OPTION.COMBINE_EDGES({ hexId }),
    );

    const [hideImports, setHideImports] = useLocalStorage(
      LocalStorageKeys.GRAPH_OPTION.HIDE_IMPORTS({ hexId }),
    );

    const [showMiniMap, setShowMiniMap] = useLocalStorage(
      LocalStorageKeys.GRAPH_OPTION.SHOW_MINI_MAP({ hexId }),
    );

    const setHideUnlinkedCellsCallback = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) =>
        sethideUnlinkedCells(evt.currentTarget.checked),
      [sethideUnlinkedCells],
    );

    const setShowLastRunTimesCallback = useCallback(() => {
      setshowLastRunTimes(!showLastRunTimes);
    }, [showLastRunTimes, setshowLastRunTimes]);

    const setCombineEdgesCallback = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) =>
        setCombineEdges(evt.currentTarget.checked),
      [setCombineEdges],
    );

    const setHideImportsCallback = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) =>
        setHideImports(evt.currentTarget.checked),
      [setHideImports],
    );

    const setFlowOrientationCallback = useCallback(
      (newOrientation: FlowOrientation) => setFlowOrientation(newOrientation),
      [setFlowOrientation],
    );

    const setShowMiniMapCallback = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) =>
        setShowMiniMap(evt.currentTarget.checked),
      [setShowMiniMap],
    );

    return (
      <Wrapper>
        <HexTooltip
          content={showLastRunTimes ? "Hide runtimes" : "Show runtimes"}
          placement="bottom"
        >
          <HexButton
            active={showLastRunTimes}
            icon={<StopwatchIcon />}
            minimal={true}
            small={true}
            onClick={setShowLastRunTimesCallback}
          />
        </HexTooltip>
        <HexTooltip content="Zoom in" placement="bottom">
          <HexButton
            icon={<ZoomInIcon />}
            small={true}
            minimal={true}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => zoomIn()}
          />
        </HexTooltip>
        <HexTooltip content="Zoom out" placement="bottom">
          <HexButton
            icon={<ZoomOutIcon />}
            small={true}
            minimal={true}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => zoomOut()}
          />
        </HexTooltip>
        <HexTooltip content="Zoom to fit" placement="bottom">
          <HexButton
            icon={<ZoomToFitIcon />}
            small={true}
            minimal={true}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => fitView({ padding: 0.2 })}
          />
        </HexTooltip>
        <HexPopover
          content={
            <HexMenu>
              <HexMenuItem
                css={`
                  padding-top: 0;
                  padding-bottom: 0;
                `}
                labelElement={
                  <HexRichSwitch
                    options={[
                      {
                        id: "vertical",
                        active: flowOrientation === FlowOrientation.Vertical,
                        text: "",
                        icon: <ArrowDownIcon />,
                        onClick: () =>
                          setFlowOrientationCallback(FlowOrientation.Vertical),
                      },
                      {
                        id: "horizontal",
                        active: flowOrientation === FlowOrientation.Horizontal,
                        text: "",
                        icon: <ArrowRightIcon />,
                        onClick: () =>
                          setFlowOrientationCallback(
                            FlowOrientation.Horizontal,
                          ),
                      },
                    ]}
                    small={true}
                    type="data-select"
                  />
                }
                shouldDismissPopover={false}
                text="Orientation"
              />
              <HexMenuItem
                shouldDismissPopover={false}
                text={
                  <HexSwitch
                    alignIndicator={Alignment.RIGHT}
                    checked={hideImports}
                    label="Hide imports"
                    onChange={setHideImportsCallback}
                  />
                }
              />
              <HexMenuItem
                shouldDismissPopover={false}
                text={
                  <HexSwitch
                    alignIndicator={Alignment.RIGHT}
                    checked={combineEdges}
                    label="Combine edges"
                    onChange={setCombineEdgesCallback}
                  />
                }
              />
              <HexMenuItem
                shouldDismissPopover={false}
                text={
                  <HexSwitch
                    alignIndicator={Alignment.RIGHT}
                    checked={hideUnlinkedCells}
                    label="Hide unlinked cells"
                    onChange={setHideUnlinkedCellsCallback}
                  />
                }
              />
              <HexMenuItem
                shouldDismissPopover={false}
                text={
                  <HexSwitch
                    alignIndicator={Alignment.RIGHT}
                    checked={showMiniMap}
                    label="Show mini map"
                    onChange={setShowMiniMapCallback}
                  />
                }
              />
            </HexMenu>
          }
          placement="bottom"
        >
          <HexTooltip content="Display" placement="bottom">
            <DisplaySettingsButton
              icon={<EyeOpenIcon />}
              minimal={true}
              rightIcon={<SingleChevronDownIcon iconSize={12} />}
              small={true}
            />
          </HexTooltip>
        </HexPopover>
      </Wrapper>
    );
  },
);
